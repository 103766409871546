import { Link } from "gatsby"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../../components/global/layout"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import Seo from "../../components/global/seo"
import SeoHead from "../../components/global/seoHead"

import ThankYouFeaturedImage from "../../../static/home/wowmakers.jpeg"


import '../../sass/pages/thank-you.scss'

export const Head = () => (
  <>
    <SeoHead
      ogImage={ThankYouFeaturedImage}
      title="Thank You"
      description="We're a 12-year old UI/UX design company specialized in user experience design (UX), user interface design (UI) & mobile and web engineering."
    />
  </> 
)

const ThankYou = () => (
  <Layout>
    <Seo
      bclass="thank-you"
      bid="thank-you"
    />
    <div className="main align-items-start">
      <ScrollIndicationHeightZero />
      <Container>
        <Row>
          <Col md={12} className="text-center mt-3 pt-5">
            <h1>Thank you!</h1>
            <p>We’ve received your message.Someone from our team will get back to you <strong>as soon as possible</strong>.</p>
            <p>Go back to <Link to="/" className="text-danger">Home Page</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

export default ThankYou
